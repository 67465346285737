import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import posed from 'react-pose';
import { Container } from './header.css';
import Title from 'components/title';
import Nav from 'components/header/nav';
import styled from 'styled-components';

const ShimaDivider1 = styled.div`
  background-color: #458db3;
  height: 5px;
`;
const ShimaDivider2 = styled.div`
  background-color: #6fada7;
  height: 5px;
`;

const ShimaDivider3 = styled.div`
  background-color: #9d424d;
  height: 5px;
`;

const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const Header = ({ title, logo }) => (
  <AnimatedContainer>
    <Container>
      <Link to="/">
        <img src={logo} width={200} alt={title}/>
      </Link>

      <Nav />
    </Container>
      <ShimaDivider1/>
      <ShimaDivider2/>
      <ShimaDivider3/>
  </AnimatedContainer>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
